/* Main container styles */
.contact-us-container {
    padding: 20px;
    background-color: #f4f4f4;
    text-align: center;
}

/* Title styles */
.contact-us-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #231834;
}

/* Flexbox container for the form and contact details */
.contact-us-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* Form and details section styles */
.contact-form,
.contact-details {
    flex: 1;
    min-width: 300px; /* Ensures each section doesn't get too small */
    margin: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Form input styles */
.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Submit button styles */
.submit-button {
    width: 100%;
    background-color: #231834;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #444;
}

/* Contact details styles */
.contact-details p {
    margin: 10px 0;
    font-size: 1rem;
    color: #333;
}

/* Social media links */
.social-media-links a {
    margin: 0 10px;
    color: #231834;
    text-decoration: none;
}

.social-media-links a:hover {
    color: #444;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .contact-us-content {
        flex-direction: column;
        align-items: center;
    }

    .contact-form,
    .contact-details {
        width: 100%;
        max-width: 500px;
        margin: 10px 0;
    }
}

.contact-link {
    color: inherit;
    text-decoration: none;
}

.contact-link:hover {
    text-decoration: underline; /* Optional: You can add underline on hover if needed */
}

/* Success and error message styling */
.success-message {
    color: green;
    font-weight: bold;
    margin-top: 15px;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 15px;
}
